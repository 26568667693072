$col_drop_down_width: 3.25rem;
$paginator-height: 40px;

.table {
  ion-row:nth-child(odd) {
    background-color: var(--ion-color-light);

    ion-col.long-text {
      &:before {
        background: linear-gradient(to right, var(--ion-color-light-transparent) 0%, var(--ion-color-light) 80%);
      }
    }
  }
  ion-row {
    padding: 0.25rem 0 0.25rem 1rem;
    cursor: pointer;

    ion-col {
      font-size: var(--ion-font-size-text);
      align-items: center;

      &:not(.ws-normal) {
        white-space: nowrap;
      }

      &.long-text {
        overflow: hidden;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 43px; // added the pixel of "right"
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--ion-color-background) 80%);
        }
      }
    }
  }

  .day-summary {
    background-color: var(--ion-color-light);
    color: var(--ion-color-medium);

    td {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      &[class^="col-"] {
        padding: 0.25rem 0; // Leave this to .25, required value
      }

      &:first-child {
        padding-left: var(--ion-padding-medium);
      }

      &:last-child {
        padding-right: var(--ion-padding-small);
      }

      span {
        font-weight: var(--ion-font-weight-text);
        margin-right: var(--ion-margin-extra-big);

        &:first-child {
          width: 7rem;
        }
        &:nth-child(2) {
          width: 6rem;
        }
      }
    }
  }
}

.table-slider-container {
  height: 100%;
}

.table-border {
  ion-row {
    padding-top: var(--ion-padding-small);
    padding-bottom: var(--ion-padding-small);
  }
  ion-row:not(:last-child) {
    border-bottom: 1px solid var(--ion-color-medium);
  }
}

/* ###################### new table component ###################### */
.table-wrapper {
  --header-filter-height: 50px;
  --header-filter-spacing-bottom: var(--ion-margin-medium);
  --table-padding: var(--ion-padding-medium);
  --sidebar-width: 25%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-areas:
    "table-filters table-filters"
    "table table-sidebar";
  grid-template-columns: calc(100% - var(--sidebar-width)) var(--sidebar-width);
  grid-template-rows: auto 1fr;

  &.no-sidebar {
    grid-template-areas:
      "table-filters table-filters"
      "table table";
  }

  p-table {
    display: block;
    height: calc(100% - $paginator-height);
  }

  .table-filters {
    grid-area: table-filters;
  }

  .table {
    grid-area: table;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .table-sidebar {
    grid-area: table-sidebar;
    min-height: 0; // Makes the sidebar scrollable
  }
}

.table {
  &.no-sidebar {
    .p-datatable-wrapper {
      border-top-right-radius: var(--default-border-radius);
    }

    p-table p-paginator {
      border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
    }
  }
}

.table {
  display: block;
  height: 100%;

  p-table p-scroller {
    &,
    & .p-scroller,
    & .p-scroller table {
      height: 100%;
    }
    & .p-scroller table {
      min-width: 760px;
    }
  }

  .p-datatable {
    height: 100%;
  }

  .p-datatable-wrapper {
    height: 100%;
    background: var(--ion-color-primary-contrast);
    border-top-left-radius: var(--default-border-radius);

    tr {
      display: flex;

      &.is-last > td.drop-down-tree-wrapper .drop-down-tree {
        &:before {
          height: 50%;
        }
        &:after {
          bottom: 0;
        }
      }
    }

    th,
    td {
      --col-width: auto;
      width: var(--col-width);
      flex: 0 0 var(--col-width);
      font-weight: var(--ion-font-weight-headline);
      white-space: nowrap;
      text-overflow: ellipsis;
      align-content: center;
      &.col-auto {
        flex: 1 1 var(--col-width);
      }

      .table-cell-subtext {
        font-size: var(--ion-font-size-text-smaller);
        font-weight: var(--ion-font-weight-small-text);
        color: var(--ion-color-medium-shade);
      }

      .hide-overflow {
        max-width: calc(100% - var(--ion-padding-small));
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.drop-down-tree-wrapper {
        position: relative;
        flex: 0 0 $col_drop_down_width;
        --col-width: $col_drop_down_width;
        align-self: stretch;

        .drop-down-tree {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 1.35rem;

          &:before,
          &:after {
            content: "";
            display: inline-block;
            background-color: var(--ion-color-gray);
          }

          &:before {
            width: 1px;
            height: 100%;
          }

          &:after {
            position: relative;
            bottom: 50%;
            width: 50%;
            height: 1px;
            vertical-align: baseline;
          }
        }
      }
    }
  }

  thead {
    height: 40px;

    th {
      background-color: var(--ion-color-primary-contrast);
      box-shadow: inset 0 -1px 0 var(--ion-color-gray-light);

      &:first-child {
        padding-left: var(--table-padding);
      }

      & > div {
        color: var(--ion-color-medium);
        display: flex;
        align-items: center;
        padding: var(--ion-padding-small-big) 0;
        &.col-center {
          justify-content: center;
        }

        .sorting-action {
          height: auto;
          padding-left: 3px;
          display: flex;
          flex-direction: column;

          ion-icon {
            position: relative;
            top: 1px;
            margin-top: -6px;
            width: var(--ion-font-size-text-smaller);

            &:last-child {
              top: -1px;
              margin-bottom: -6px;
            }

            &.active {
              color: var(--ion-color-medium-contrast);
            }
          }
        }
      }
    }

    & > tr {
      height: inherit;
    }
  }

  tbody tr {
    --row-height: 50px;
    height: var(--row-height);
    box-shadow: inset 0 -1px 0 var(--ion-color-gray-light); // does not add additional height to row
    cursor: pointer;
    transition: background-color 250ms ease-in-out;

    &:hover {
      background-color: rgb(var(--ion-color-gray-light-rgb), 0.3);
    }

    &.selected {
      background-color: var(--ion-color-gray-light);
    }

    td:first-child {
      padding-left: var(--table-padding);
    }

    &.skeleton-row td {
      padding-right: var(--ion-padding-small);
    }

    td {
      padding: var(--ion-padding-small) var(--ion-padding-extra-small) var(--ion-padding-small) 0;

      &:last-child {
        padding-right: var(--ion-padding-medium-big);
      }

      &.col-action {
        text-align: right;
        justify-content: flex-end;
        display: flex;
        gap: var(--ion-padding-small);

        ion-icon[name="ellipsis-vertical"] {
          height: 24px;
        }
      }
    }
  }

  thead > tr,
  tbody > tr {
    .col-bulk {
      flex: 0 0 3.1rem;

      &.hidden {
        padding-left: 0;
        flex: unset;
        display: none;
      }
      ion-item {
        width: 100%;

        &::part(native) {
          --inner-padding-start: 0;
          --inner-padding-end: 0;
          padding: 0;
        }
      }
      ion-checkbox {
        --size: 12px;
        --border-width: 1px;
        --border-color: var(--ion-color-black);

        &::part(container) {
          // TODO remove with Ionic v7.4.1 see https://github.com/ionic-team/ionic-framework/issues/27169
          margin: 1.2rem 1.1rem; // used to center checkbox input -> as long as the "checkbox-wrapper" is not exposed as shadow part, that seems to be the only solution
        }
      }

      ion-icon {
        color: var(--ion-color-black);
      }
    }

    .first-col-padding {
      padding: 0 var(--ion-padding-extra-small) 0 var(--ion-padding-small);
    }
  }

  .no-results-row {
    box-shadow: none;
    cursor: default;
    height: 100%;

    &:hover {
      background-color: transparent;
    }

    .no-results {
      position: absolute;
      display: flex;
      flex-direction: column;
      color: var(--ion-color-medium);
      width: 65%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      h1 {
        margin-top: 0;
        margin-bottom: var(--ion-margin-big);
        font-weight: var(--ion-font-weight-subheadline);
        text-align: center;
      }

      .no-results-filters {
        display: flex;
        gap: var(--ion-padding-medium);
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: var(--ion-margin-big);
      }

      .no-results-action {
        display: flex;
        gap: var(--ion-padding-medium);

        ion-button {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.filter-container {
  display: flex;
}

.left-inner-container {
  display: flex;
  gap: var(--ion-padding-big);
  width: calc(100% - var(--sidebar-width) + var(--header-filter-height)); // filter button is square sized
  padding: var(--ion-padding-small) 0;

  > *:not(.search-container) {
    flex-shrink: 1;
  }


  .filter-button {
    position: relative;
    margin: 0;
    height: 100%;
    width: var(--header-filter-height);
    flex-shrink: 0;
    --padding-top: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-bottom: 0;
    padding-left: var(--ion-padding-small);

    ion-icon {
      font-size: var(--ion-font-size-headline-bigger);
    }

    &.active:after {
      content: "";
      position: absolute;
      top: 10%;
      right: 0;
      height: 0.75rem;
      width: 0.75rem;
      background-color: var(--ion-color-primary);
      border-radius: 50%;
    }
  }
}

.right-inner-container {
  display: flex;
  gap: var(--ion-padding-small);
  margin-left: auto;
  padding: var(--ion-padding-small) 0;

  ion-spinner {
    margin: 0;
  }
}

// used for setting padding for the table in bulk action modal
p-table.bulk-action-modal-table {
  .p-datatable-wrapper {
    padding: var(--ion-padding-medium) 0;
  }
}


// Paginator
p-table p-paginator {
  display: flex;
  align-content: center;
  height: $paginator-height;
  background: var(--ion-color-white);
  border-radius: 0 0 0 var(--default-border-radius);
  border-top: 1px solid var(--ion-color-light);
  font-weight: var(--ion-font-weight-headline);

  & > div {
    width: 100%;
    padding: 0 var(--ion-padding-medium);
    display: grid;
    grid-template-columns: 1.5fr repeat(5, auto) 1fr auto ;
    justify-items: flex-start;

    & > *:nth-child(1) {
      grid-column: 1 / 2;
      text-align: left; /* Align to the left */
    }

    & > *:nth-child(2),
    & > *:nth-child(3),
    & > *:nth-child(4),
    & > *:nth-child(5),
    & > *:nth-child(6) {
      justify-self: center;
    }

    & > *:nth-child(7) {
      grid-column: 7 / 8;
      justify-self: end; /* Align to the right */
    }

    & > *:nth-child(8) {
      grid-column: 8 / 9;
      justify-self: start; /* Align next to the dropbox */
    }


    & > *:last-child {
      justify-self: flex-end;
    }

    .p-paginator-current {
      height: 100%;
    }

    button {
      padding: var(--ion-padding-small);
      background: transparent;
      margin: 0 var(--ion-margin-extra-small);
      aspect-ratio: 1 / 1;
      width: 30px;
      transition: background 250ms ease-in-out;
      font-size: var(--ion-font-size-text);
      font-weight: var(--ion-font-weight-headline);
      border-radius: var(--default-border-radius);

      &:hover {
        background: var(--ion-color-gray-light);
      }

      &.p-highlight {
        color: var(--ion-color-primary);
        background: var(--menu-icon-background-active);
      }
    }

    p-dropdown {
      justify-self: flex-end;
      margin-right: var(--ion-margin-extra-small);

      .p-dropdown-trigger > * {
        align-items: center;
        display: flex;
        margin-left: var(--ion-margin-extra-small);
      }
    }
  }
}

.search-container {
  display: flex;
  min-width: 19rem;
  margin-left: auto;
  padding-top: var(--ion-padding-extra-small);
  padding-bottom: var(--ion-padding-extra-small);
}

.search-button {
  display: flex;
  gap: var(--ion-padding-small);
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary-contrast);
  border-radius: var(--default-border-radius);
  padding: 0 var(--ion-padding-small);
  font-size: var(--orders-page-font-size);
  font-weight: var(--orders-page-font-weight);
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  max-height: 42px;
  align-self: center;

  .placeholder {
    content: attr(data-placeholder);
    color: var(--ion-color-medium);
  }

  .search-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ion-icon {
    font-size: var(--ion-font-size-headline);
    color: var(--ion-color-medium);
    flex-shrink: 0;

    &.clickable {
      margin-left: auto;
      // 2px to generate same space as the search icon
      padding-right: 2px;
    }
  }
}
