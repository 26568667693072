ion-checkbox {
  &::part(label) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

ion-checkbox.checkbox-disabled-visible.checkbox-disabled {
  &::part(label) {
    opacity: 1;
  }
  &::part(container){
    border-color: var(--ion-color-medium-tint);
    background: var(--ion-color-medium-tint);
  }
}
