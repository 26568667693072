.calendar-box {
  display: block;

  & .p-calendar .p-component .p-datepicker-group-container .p-datepicker-group {
    display: flex;
    flex-direction: column;

    & > div:first-child {
      flex: 0 0 auto;
    }
    & > div:last-child {
      flex: 1 1 100%;
    }
  }
  .p-datepicker {
    padding: var(--ion-padding-medium-big);
    .p-datepicker-header {
      .p-datepicker-title {
        button {
          padding: var(--ion-padding-extra-small) var(--ion-padding-small);
          font-size: var(--ion-font-size-text)
        }
      }
    }
  }
  .p-calendar .p-component .p-datepicker-group-container .p-datepicker-group table {
    tr {
      margin-bottom: 0.5rem;
      td {
        padding: 0;

        & > span {
          width: 2.0rem;
          height: 2.0rem;
          margin: 0.2rem
        }

        span {
          font-size: var(--ion-font-size-text);
        }
      }
    }
  }
}

.p-calendar {
  width: 100%;
}
.p-datepicker {
  padding: 0.5rem;
  background: transparent;
  color: var(--ion-color-black);
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: var(--ion-padding-small);
  color: var(--ion-color-black);
  background: transparent;
  font-weight: 600;
  margin: 0;
  display: flex;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: var(--ion-color-black);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  border-color: transparent;
  background: var(--ion-color-light);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  flex: 1 1 100%;
  text-align: center;
  line-height: 2rem;
  border-radius: var(--default-border-radius);
  background: var(--ion-color-light);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: var(--ion-color-black);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-size: var(--ion-font-size-navigation);
  font-weight: 600;
  padding: 0.5rem;
}/*
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #9C27B0;
}*/
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: var(--i0on-font-size-navigation);
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
  font-weight: var(--ion-font-weight-small-text);
}
.p-datepicker table td {
  padding: var(--ion-padding-extra-small);
  text-align: center;
}
.p-datepicker table td > span {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  border: 1px solid transparent;
  position: relative;
  overflow: visible;

  &,
  & span {
    font-size: var(--ion-font-size-subheadline);
  }

  &.p-disabled {
    color: var(--ion-color-gray);
  }

  .has-date:after {
    content: '';
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -5px;
    background: var(--ion-color-primary);
  }
}
.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker table td.p-datepicker-today > span {
  background: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);

  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: transparent;
    border: 2px solid var(--ion-color-primary);
    border-radius: 50%;
  }

  &,
  & span {
    font-weight: var(--ion-font-weight-headline);
  }
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: var(--ion-color-black);
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: var(--ion-font-size-navigation);
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary);
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: #6c757d;
  right: 2.857rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}

/* month-picker */
p-calendar[view="month"] {
  position: relative;

  &:after {
    content: url(/assets/icons/caret-down-sharp.svg);
    display: inline-block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .p-inputtext {
    width: 5rem; // width of month year to month year input
    background: unset;
    font-weight: var(--ion-font-weight-subheadline);
    border: 1px solid transparent;
    border-radius: var(--default-border-radius);
    box-shadow: none;
    cursor: pointer;
    transition: all 250ms ease-in-out;
  }

  .p-datepicker-monthpicker {
    width: 22rem; // preferable width of month year picker
  }

  --month-gap: var(--ion-padding-extra-small);
  .p-monthpicker {
    display: flex;
    flex-wrap: wrap;
    gap: var(--month-gap);
  }

  .p-monthpicker-month {
    flex-basis: calc(33% - var(--month-gap));
  }

  .p-disabled {
    color: var(--ion-color-medium);
  }
}


// anuglar-calendar

.cal-week-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  &,
  .cal-time-events,
  .cal-day-column,
  .cal-day-headers,
  .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border: none;
  }
  .cal-hour .cal-hour-segment {
    transition: background 100ms ease-in-out;
  }


  .cal-time {
    font-weight: var(--ion-font-weight-text);
    text-align: right;
    transform: translateY(-75%);
  }

  .cal-day-headers {
    padding-left: 100px;

    .cal-header {
      padding-bottom: 0;
      &:not(:last-child) {
        border-left: none;
        border-right: none;
      }
      border-bottom: 1px solid var(--ion-color-light-shade);

      &, span {
        font-size: var(--ion-font-size-card-title);
        font-weight: var(--ion-font-weight-subheadline);
      }

      &.cal-past,
      &.cal-future,
      &.cal-today,
      &.cal-weekend {
        background: var(--ion-color-background);

        &, span {
          opacity: 1;
          color: var(--ion-color-black);
        }
      }

      &.cal-today {
        span {
          font-size: var(--ion-font-size-headline);
          padding: var(--ion-padding-small);
          color: var(--ion-color-primary-contrast);
          background-color: var(--ion-color-primary);
          display: inline-block;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          transform: translateY(calc(-.25 * var(--ion-padding-small)));
        }
      }
    }
  }

  .cal-time-label-column {
    width: 100px;
    position: relative;

    ion-text {
      font-size: var(--ion-font-size-navigation);
    }

    .cal-hour {
      background: var(--ion-color-background);
    }
  }

  .cal-all-day-events {
    border-left: none;
    border-right: none;
    border-bottom-width: 2px;
    padding-top: 1px;
    padding-bottom: var(--ion-padding-medium-big);


    .cal-time-label-column {
      justify-content: flex-start;
      align-items: flex-start;
    }

    .cal-events-row {
      margin-left: 100px;
      height: 28px;
    }

    .cal-event {
      border-color: var(--ion-color-background);
      border-radius: var(--default-border-radius) !important;
      padding-left: var(--ion-padding-small-big);
      padding-right: var(--ion-padding-small-big);
    }

  }

  .cal-day-columns {
    width: 100%;

    .cal-day-column .cal-hour-segment.cal-hour-start:before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      top: -5%;
      left: 0;
      right: 0;
      background: var(--ion-color-light-shade);
    }
  }

  &,
  .cal-hour-odd {
    background: var(--ion-color-background);
  }


  .cal-time-events {
    height: auto;
    overflow: auto;
    overflow-x: hidden;
    padding-top: var(--ion-padding-big);
    padding-bottom: var(--ion-padding-big);
  }

  .cal-current-time-marker {
    background: var(--ion-color-medium-shade);
    height: 1px;

    &:before,
    &:after {
      content: '';
      display: block;
      background: var(--ion-color-medium-shade);
    }

    &:before {
      height: 1px;
      width: 200vw;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    &:after {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      position: absolute;
      left: var(--ion-padding-small);
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      position: absolute;
      background: var(--ion-color-medium-shade);
      color: var(--ion-color-white);
      top: 50%;
      transform: translateY(-50%);
      width: 80px;
      text-align: center;
      font-weight: var(--ion-font-weight-headline);
      padding: var(--ion-padding-extra-smaller);
      border-radius: var(--small-border-radius);
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        display: block;
        width: 20px;
        height: 100%;
        background: var(--ion-color-white);


      }
    }
  }
  .cal-event-title * {
    font-size: var(--ion-font-size-text-smaller);
    font-weight: var(--ion-font-weight-subheadline);
  }
  mwl-calendar-event-title,
  .cal-event-title,
  .cal-event-title *{
    // Prevent popover from false positioning on text instead of event-container
    pointer-events: none;
  }

  .cal-event-title-short div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ion-icon {
      margin-right: var(--ion-margin-extra-small);
    }
  }

  .cal-time-events {
    .cal-event {
      padding: 0 5%;

      .cal-event-title {
        padding: var(--ion-padding-extra-small) 5% var(--ion-padding-small);
        height: 100%;
        line-height: 1.2;
        position: relative;
        pointer-events: none;

        &.cal-event-title-short div {
          line-height: 1.4;

          ion-icon {
            transform: translateY(2px);
            margin-right: var(--ion-margin-extra-small);
          }
        }

        &:not(.cal-event-title-short) {
          white-space: normal;
          display: flex;
          flex-direction: column;

          .cal-creator-icon {
            min-height: fit-content;
            max-width: 100%;
            margin-top: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: var(--ion-padding-small);
          bottom: var(--ion-padding-small);
          left: 0;
          border-left-width: 1px;
          border-left-style: solid;
        }

        ion-icon {
          width: 13px;
          height: 13px;
          transform: translateY(3px);
          margin-right: var(--ion-margin-extra-small);
        }

        .cal-event-private {
          font-weight: var(--ion-font-weight-text);
        }

        .cal-creator-icon {
          border-radius: var(--small-border-radius);
          border-width: 1px;
          border-style: solid;
          display: block;
          width: fit-content;
          padding: 0 var(--ion-padding-extra-small);
          height: 20px;
          text-align: center;
          line-height: 18px;
        }
        .username-avatar {
          height: 20px;
          width: 20px;
          font-size: 9px;
          margin-top: auto;
        }
        .cal-event-start-time{
          font-weight: normal;
        }
      }
    }
  }
}


mwl-calendar-week-view {
  display: block;
  height: 100%;
  overflow: hidden;
}

mwl-calendar-event-title {
  display: block;
  height: 100%;
}
