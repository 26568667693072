
.username-avatar {
  color: var(--ion-color-cal-pharmacy-color);
  background: var(--ion-color-cal-pharmacy-bg);
  border: 1px solid var(--ion-color-cal-pharmacy-color);
  border-radius: 50%;
  font-size: var(--ion-font-size-subheadline-bigger);
  font-weight: var(--ion-font-weight-headline);
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-user-avatar {
    color: var(--ion-color-cal-user-color);
    background: var(--ion-color-cal-user-bg);
    border: 1px solid var(--ion-color-cal-user-color);
  }
  &.is-sanacorp-avatar {
    color: var(--ion-color-cal-sanacorp-color);
    background: var(--ion-color-cal-sanacorp-bg);
    border: 1px solid var(--ion-color-cal-sanacorp-color);
  }
  &.is-disabled {
    color: var(--ion-color-medium-shade);
    background: transparent;
    border: 1px solid var(--ion-color-medium-shade);
  }


  &.username-avatar-inline {
    display: inline-flex;
    font-size: var(--ion-font-size-text-smaller);
    width: 24px;
    margin-right: var(--ion-margin-medium);
  }
}
