/*Added !importants to overwrite the inline styles*/

markdown {
  &.markdown-compact {
    p, span, a, ul, ol, li, em, b, strong, i, del {
      line-height: var(--ion-line-height-small) !important;
    }
    p {
      margin-bottom: 0 !important;
    }
  }

  &:not(.markdown-compact) {
    p, span, a, ul, ol, li, em, b, strong, i, del {
      font-size: var(--ion-font-size-text) !important;
      line-height: var(--ion-line-height-big) !important;
    }
    p {
      margin-bottom: var(--ion-margin-medium) !important;
    }
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: var(--ion-margin-small) !important;
  }

  h1 {
    font-size: var(--ion-font-size-number-boxes) !important;
    font-weight: var(--ion-font-weight-headline) !important;
  }

  h2 {
    font-size: var(--ion-font-size-big) !important;
    font-weight: var(--ion-font-weight-headline) !important;
    margin-bottom: var(--ion-margin-small-big) !important;
  }

  h3 {
    font-size: var(--ion-font-size-headline-bigger) !important;
    margin-bottom: var(--ion-margin-medium-big) !important;
  }

  h4 {
    font-size: var(--ion-font-size-headline) !important;
    font-weight: var(--ion-font-weight-headline) !important;
    margin-bottom: var(--ion-margin-extra-small) !important;
  }

  h5 {
    margin-bottom: var(--ion-margin-small) !important;
  }

  h6 {
    font-size: var(--ion-font-size-small-text) !important;
    margin-bottom: var(--ion-margin-extra-small) !important;
  }

  /**
   * Increased font size on detail pages like news detail
   */
  &.detail-page {
    &:not(.markdown-compact) {
      p, span, a, ul, ol, li, em, b, strong, i, del {
        font-size: var(--ion-font-size-subheadline) !important;
      }
    }

    h1 {
      font-size: var(--ion-font-size-pin-code) !important;
      font-weight: var(--ion-font-weight-headline) !important;
    }

    h2 {
      font-size: var(--ion-font-size-headline-bigger) !important;
      font-weight: var(--ion-font-weight-headline) !important;
      margin-bottom: var(--ion-margin-small-big) !important;
    }

    h3 {
      font-size: var(--ion-font-size-subheadline) !important;
      font-weight: var(--ion-font-weight-headline) !important;
      margin-bottom: var(--ion-margin-medium-big) !important;
    }

    h4 {
      font-size: var(--ion-font-size-text) !important;
      font-weight: var(--ion-font-weight-headline) !important;
      margin-bottom: var(--ion-margin-extra-small) !important;
    }

    h5 {
      font-size: var(--ion-font-size-text) !important;
      margin-bottom: var(--ion-margin-small) !important;
    }

    h6 {
      font-size: var(--ion-font-size-small-text) !important;
      margin-bottom: var(--ion-margin-extra-small) !important;
    }
  }

  &.list-item {
    p, span, p span {
      padding: var(--ion-padding-small) 0;
      line-height: 1.2 !important;
      font-weight: var(--ion-font-weight-headline)
    }
  }

  video {
    max-width: 100%;
    max-height: 100%;
  }

  *:first-child {
    margin-top: 0;
  }

  p {
    width: 80%;
  }

  &.full-width-paragraphs p {
    width: 100%;
  }

  // because <figure> wrapped around <table>
  .table {
      height: auto;
      width: 100% !important;
      margin-left: 0;
      margin-right: 0;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
  }

  table {
    border: none !important;

    tbody {
        tr {
            td {
                border: none !important;
                padding: var(--ion-padding-small) !important;
            }
        }
    }
  }

  ul {
    padding-left: 0;
    list-style-type: none !important;

    li {
      background: url('/assets/icons/bullet_point.svg') no-repeat;
      padding-left: var(--ion-padding-big);
      background-position-y: 6px;
      background-position-x: left;
      background-size: 15px;
    }
  }

  .callout {
    background-color: var(--ion-color-primary-light);
    padding: var(--ion-padding-extra-small) var(--ion-padding-medium) var(--ion-padding-extra-small) var(--ion-padding-extra-big);
    position: relative;
    border-radius: var(--small-border-radius);

    &:before {
        content: 'i';
        display: block;
        height: 10px;
        width: 10px;
        line-height: 10px;
        padding: 4px;
        position: absolute;
        top: 50%;
        left: var(--ion-padding-small);
        transform: translateY(-50%);

        text-align: center;
        border-radius: 50%;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);

        font-size: 12px;
        font-family: monospace;

    }
  }
}
